<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">银行账户</div>
      <div class="back" @click="goback">返回上一页</div>
    </div>
    <div class="from-box">
      <div class="line">国内工资卡</div>
      <div class="line-box">
        <div class="line-title">中文名</div>
        <el-input
          v-model="params.HomeAccountNameCHS"
          placeholder="国内工资卡-中文名"
        />
      </div>
      <div class="line-box">
        <div class="line-title">英文名</div>
        <el-input
          v-model="params.HomeAccountNameEN"
          placeholder="国内工资卡-英文名"
        />
      </div>
      <div class="line-box">
        <div class="line-title">账号</div>
        <el-input
          v-model="params.HomeBankAccount"
          placeholder="国内工资卡-账号"
        />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">开户行</div>
        <el-select v-model="params.HomeBankType" placeholder="请选择">
          <el-option
            v-for="item in Banks"
            :key="item.BankID"
            :label="item.BankName"
            :value="item.BankID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box line-box3">
        <div class="line-title">SWIFT代码</div>
        <el-input
          v-model="params.HomeSWIFTCode"
          placeholder="国内工资卡-SWIFT代码"
        />
      </div>
      <div class="line">海外补助卡</div>
      <div class="line-box">
        <div class="line-title">中文名</div>
        <el-input
          v-model="params.OverSeasAccountNameCHS"
          placeholder="海外补助卡-中文名"
        />
      </div>
      <div class="line-box">
        <div class="line-title">英文名</div>
        <el-input
          v-model="params.OverSeasAccountNameEN"
          placeholder="海外补助卡-英文名"
        />
      </div>
      <div class="line-box">
        <div class="line-title">账号</div>
        <el-input
          v-model="params.OverSeasBankAccount"
          placeholder="海外补助卡-账号"
        />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">开户行</div>
        <el-select v-model="params.OverSeasBankType" placeholder="请选择">
          <el-option
            v-for="item in Banks"
            :key="item.BankID"
            :label="item.BankName"
            :value="item.BankID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box line-box3">
        <div class="line-title">SWIFT代码</div>
        <el-input
          v-model="params.OverSeasSWIFTCode"
          placeholder="海外补助卡-SWIFT代码"
        />
      </div>
      <div class="line">当地卡</div>
      <div class="line-box">
        <div class="line-title">中文名</div>
        <el-input
          v-model="params.LocalAccountNameCHS"
          placeholder="当地卡-中文名"
        />
      </div>
      <div class="line-box">
        <div class="line-title">英文名</div>
        <el-input
          v-model="params.LocalAccountNameEN"
          placeholder="当地卡-英文名"
        />
      </div>
      <div class="line-box">
        <div class="line-title">ACCOUNT No.</div>
        <el-input
          v-model="params.LocalACCOUNNo"
          placeholder="当地卡-ACCOUNT No. "
        />
      </div>
      <div class="line-box">
        <div class="line-title">IBAN No.</div>
        <el-input v-model="params.LocalIBANNo" placeholder="当地卡-IBAN No." />
      </div>
      <div class="line-box">
        <div class="line-title">开户行</div>
        <el-select v-model="params.LocalBankAccount" placeholder="请选择">
          <el-option
            v-for="item in Banks"
            :key="item.BankID"
            :label="item.BankName"
            :value="item.BankID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">BANK CODE</div>
        <el-input
          v-model="params.LocalBankCode"
          placeholder="当地卡-BANK CODE"
        />
      </div>
      <div class="line-box">
        <div class="line-title">注册手机号码</div>
        <el-input
          v-model="params.LocalRegisterPhone"
          placeholder="当地卡-注册手机号码"
        />
      </div>
      <div class="line-box">
        <div class="line-title">注册薪资水平</div>
        <el-input
          v-model="params.LocalRegisterSalary"
          placeholder="当地卡-注册薪资水平"
        />
      </div>
      <div class="line-box">
        <div class="line-title">有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.LocalEffectiveDates"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="buttons">
        <el-button class="pop-close" @click="goback">取消</el-button
        ><el-button class="pop-save" @click="addCompanies">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getBankAccount, getBankAccountBasic } from "@/api/other";
export default {
  data() {
    return {
      params: {
        ID: null,
        HomeAccountNameCHS: null,
        HomeAccountNameEN: null,
        HomeBankAccount: null,
        HomeBankType: null,
        HomeSWIFTCode: null,
        OverSeasAccountNameCHS: null,
        OverSeasAccountNameEN: null,
        OverSeasBankAccount: null,
        OverSeasBankType: null,
        OverSeasSWIFTCode: null,
        LocalAccountNameCHS: null,
        LocalAccountNameEN: null,
        LocalACCOUNNo: null,
        LocalIBANNo: null,
        LocalBankAccount: null,
        LocalBankCode: null,
        LocalRegisterPhone: null,
        LocalRegisterSalary: null,
        LocalEffectiveDates: null,
      },
      Banks: [],
    };
  },
  methods: {
    goback() {
      this.$router.push("/ordinary");
    },
    uploadAttachment() {},
    addCompanies() {},
    getBankAccount() {
      getBankAccount().then((res) => {
        if (res.status === 200) {
          this.params = res.response;
        }
      });
    },
    getBankAccountBasic() {
      getBankAccountBasic().then((res) => {
        if (res.status === 200) {
          const data = res.response;
          this.Banks = data.Banks;
        }
      });
    },
  },

  created() {
    this.getBankAccount();
    this.getBankAccountBasic();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
  }
  .from-box {
    width: 100%;
    min-height: calc(100% - 40px);
    background-color: #fff;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    padding-top: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-content: flex-start;
    .line-box {
      width: 30%;
      margin-bottom: 24px;
      .line-title {
        font-size: 14px;
        color: #100303;
        margin-bottom: 10px;
      }
      .el-select {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .bulue-title {
        color: #257eff;
        cursor: pointer;
      }
    }
    .line-box2 {
      width: 24%;
    }
    .line-box3 {
      width: 49%;
    }
    .line-upload {
      width: 100%;
    }
    .line {
      width: 100%;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bolder;
    }
    .upload-load {
      display: flex;
      height: 34px;
      //   margin-top: 24px;
      .left {
        width: 100%;
        border: 1px solid #d2d6de;
        border-right: none;
        .name {
          display: flex;
          align-items: center;
          height: 34px;
          color: #555;
          font-size: 14px;
          text-indent: 2px;
        }
      }
      .btn-up {
        padding: 0;
        margin: 0;
        border: none;
        width: 90px;
        flex-shrink: 0;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #3c8dbc;
        border: 1px solid #367fa9;
        color: #fff;
        font-size: 14px;
        .icon {
          margin-right: 5px;
        }
      }
      .disabled {
        cursor: no-drop;
      }
      .btn-up:hover {
        border-color: #204d74;
        background-color: #367fa9;
      }
    }
    .buttons {
      width: 100%;

      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
